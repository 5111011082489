button, a {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
button:focus {
  outline: none;
}
.App {
  width: 100%;
  min-height: 100vh;
  background-color: #eeeeee;
  color: #000;
}
.container {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 30px;
}
.page_container {
  min-height: calc(100vh - 100px);
}
.header_container {
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 30px;
}
.main_header {
  background-color: #454344;
}
.login_logo {
  width: 100%;
  max-width: 80px;
  margin: 0 auto;
  display: block;
  padding: 15px;
}
.page_header {
  width: 100%;
  display: table;
  margin: 0 auto;
  height: 70px;
  padding: 0;
}
.logo_box {
  display: table-cell;
  width: 80px;
  vertical-align: middle;
}
.logo {
  width: 100%;
  max-width: 50px;
  display: block;
}
.titleBox {
  display: table-cell;
  width: auto;
  text-align: left;
  vertical-align: middle;
}
.titleItem {
  display: inline-block;
  padding: 10px;
  color: #fff !important;
  text-decoration: none;
  font-size: 18px;
  font-weight: 300;
}
.main_nav {
  display: table-cell;
  width: auto;
  text-align: right;
  vertical-align: middle;
}
.nav_item {
  display: inline-block;
  padding: 10px;
  color: #fff !important;
  text-decoration: none;
  font-size: 18px;
  font-weight: 300;
}
.nav_item:hover {
  opacity: 0.8;
  text-decoration: none;
}
.nav_item i {
  display: none;
}
.nav_item.active {
  color: #f26135 !important;
}
.active_line {
  text-align: right;
  padding: 15px 0;
}
.inactive_btn, .active_btn {
  width: 110px;
  display: inline-block;
  text-align: center;
  color: #fff !important;
  padding: 3px 10px 5px;
  border-radius: 60px;
  text-decoration: none !important;
}
.inactive_btn {
  background: #0E8500;
}
.active_btn {
  background: #D12815;
}

.page_title {
  text-align: center;
  width: 100%;
  display: block;
  font-size: 20px;
  margin: 20px 0;
  font-weight: 400;
}
.page_text {
  text-align: center;
  width: 100%;
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
}
.btn.btn-primary {
  margin: 0 auto;
  display: block;
}
input[type="file"] {
  width: 100%;
  margin-bottom: 10px;
  display: block;
}
::-webkit-file-upload-button {
  color: #000;
  border: 2px solid #f26135;
  display: block;
  border-radius: 0;
}
::-webkit-file-upload-button:focus {
  box-shadow: none;
}
span[aria-hidden="true"] span {
  display: none;
}
input:focus {
  box-shadow: none;
}
.separator {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    border-right: 1px solid #9f9f9f;
}
@media (max-width: 768px) {
  .page_container {
    min-height: calc(100vh - 115px);
  }
  .separator {
    border-right: none;
    border-bottom: 1px solid #9f9f9f;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .logo_box, .main_nav {
    display: block;
    width: 100%;
    text-align: center;
  }
  .logo {
    max-width: 120px;
    margin: 10px auto;
  }
  .nav_item {
    vertical-align: middle;
  }
  .nav_item i {
    display: inline-block;
    font-size: 24px;
  }
  .nav_item span {
    display: none;
  }
  .inactive_btn, .active_btn {
    font-size: 14px;
    width: 90px;
  }
}

.loader_block {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
}
.loader {
	height: 32px;
  width: 32px;
  display: block;
  margin: 0 auto;
	-webkit-animation: loader-1-1 4.8s linear infinite;
	        animation: loader-1-1 4.8s linear infinite;
}
@-webkit-keyframes loader-1-1 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-1-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	clip: rect(0, 32px, 32px, 16px);
	-webkit-animation: loader-1-2 1.2s linear infinite;
	        animation: loader-1-2 1.2s linear infinite;
}
@-webkit-keyframes loader-1-2 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(220deg); }
}
@keyframes loader-1-2 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(220deg); }
}
.loader span::after {
	content: "";
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	clip: rect(0, 32px, 32px, 16px);
	border: 3px solid #f26135;
	border-radius: 50%;
	-webkit-animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-1-3 {
	0%   { -webkit-transform: rotate(-140deg); }
	50%  { -webkit-transform: rotate(-160deg); }
	100% { -webkit-transform: rotate(140deg); }
}
@keyframes loader-1-3 {
	0%   { transform: rotate(-140deg); }
	50%  { transform: rotate(-160deg); }
	100% { transform: rotate(140deg); }
}
